import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "./Widget2";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";

const Wrapper = styled.div``;

const FullScreenDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;

  & input {
    width: 60%;
    height: 40px;
    color: white;
    font-size: 24px;
    padding: 10px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    cursor: pointer;

    ::placeholder {
      font-size: 24px;
    }

    :focus {
      outline: none;
    }
  }
`;

class Searching extends React.Component {
  state = {
    keywords: "",
    showSearchDialog: false
  };
  render() {
    let { showSearchDialog } = this.state;

    return (
      <Wrapper>
        <Icon.Search
          color={"#999999"}
          style={{ cursor: "pointer" }}
          onClick={() => this.setState({ showSearchDialog: true })}
        />
        {showSearchDialog && (
          <FullScreenDialog>
            <Icon.Close
              onClick={() => this.setState({ showSearchDialog: false })}
              color={"white"}
              style={{
                position: "absolute",
                top: "25px",
                right: 25,
                cursor: "pointer"
              }}
            />
            <Widget.Center style={{ alignItems: "stretch" }}>
              <Widget.Row
                css={{ justifyContent: "center", alignItems: "center" }}
              >
                <input
                  autoFocus
                  placeholder="SEARCH"
                  onChange={e => this.setState({ keywords: e.target.value })}
                  onKeyPress={this._handleEnterPress}
                />
                <div onClick={() => this._goToSearchResults()}>
                  <Icon.Search color={"white"} style={{ cursor: "pointer" }} />
                </div>
              </Widget.Row>
            </Widget.Center>
          </FullScreenDialog>
        )}
      </Wrapper>
    );
  }

  _handleEnterPress = e => {
    if (e.key === "Enter") {
      return this._goToSearchResults();
    }
    return;
  };

  _goToSearchResults = () => {
    let { search, navActions } = this.props;
    let { keywords } = this.state;

    search.go(keywords);
    this.setState({ showSearchDialog: false });
    navActions.push(`/search?q=${keywords}`);
  };
}

export default connect(
  (state, ownProps) => ({}),
  ActionCreator
)(Searching);
