import React from "react";
import styled from "styled-components";

export default styled.div`
  width: 250px;
  height: 250px;
  margin: 20px;

  /* iPad landscape */
  @media screen and (max-width: 1025px) {
    width: 192px;
    height: 192px;
    margin: 3px;
  }

  /* iPhoneX landscape */
  @media screen and (max-width: 813px) {
    width: 188px;
    height: 188px;
    margin: 3px;
  }

  /* iPad portrait */
  @media screen and (max-width: 769px) {
    width: 172px;
    height: 172px;
    margin: 3px;
  }

  /* iPhone Plus landscape */
  @media screen and (max-width: 737px) {
    width: 165px;
    height: 165px;
    margin: 3px;
  }

  /* Pixel2 landscape */
  @media screen and (max-width: 732px) {
    width: 232px;
    height: 232px;
    margin: 10px;
  }

  /* iPhone6 landscape */
  @media screen and (max-width: 732px) {
    width: 200px;
    height: 200px;
    margin: 10px;
  }

  /* iPhone Plus */
  @media screen and (max-width: 415px) {
    width: 190px;
    height: 190px;
    margin: 3px;
  }

  /* Pixel (XL) */
  @media screen and (max-width: 412px) {
    width: 188px;
    height: 188px;
    margin: 3px;
  }

  /* iPhone + Galaxy S5 */
  @media screen and (max-width: 376px) {
    width: 164px;
    height: 164px;
    margin: 3px;
  }

  /* iPhone SE */
  @media screen and (max-width: 321px) {
    width: 250px;
    height: 250px;
    margin: 20px;
  }
`;
