import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Widget from "./Widget2";
import ActionCreator from "../ActionCreator";

const SortTypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SortType = styled.span`
  cursor: pointer;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  color: white;
  border-radius: 18px;
  background-color: ${props => (props.active ? "#999999" : "#CFCFCF")};

  :hover {
    background-color: #999999;
  }
`;

class Sorting extends React.Component {
  render() {
    let { sort } = this.props;
    return (
      <Widget.Col>
        <SortTypesContainer>
          <SortType
            active={sort.value === "-updated"}
            onClick={() => sort.go(sort.value === "-updated" ? "" : "-updated")}
          >
            最新
          </SortType>
          <SortType
            active={sort.value === "updated"}
            onClick={() => sort.go(sort.value === "updated" ? "" : "updated")}
          >
            最舊
          </SortType>
        </SortTypesContainer>
      </Widget.Col>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    //
  }),
  ActionCreator
)(Sorting);
