import React, { Component, Fragment } from "react";
import styled from "styled-components";
import * as Widget from "./Widget";
import * as Icon from "./Icon";

const Wrapper = styled.div`
  width: 100%;
  ${props => props.css}
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  & > a {
    flex: 1;
    font-size: 14px;
    color: ${props => (props.selected ? "teal" : "#414141")};
    cursor: pointer;
  }

  & > .expand {
    background: transparent;
    cursor: pointer;
    height: 24px;
  }
`;

class TreeNodeWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: this._shouldExpand()
    };
  }

  render() {
    let { node, onNodeClick, activeNode, css = "" } = this.props;
    const { expand } = this.state;
    const hasChildren = node.children && node.children.length > 0;
    const selected = activeNode === node.name;

    return (
      <Wrapper css={css}>
        {node.name === "root" ? null : (
          <FilterRow selected={selected}>
            <a onClick={() => onNodeClick(node.name)}>
              {node.display || node.name}
            </a>

            {hasChildren && (
              <div
                className="expand"
                onClick={() => this.setState({ expand: !expand })}
              >
                {expand ? (
                  <Icon.KeyboardArrowDown color="#4e4e4e" />
                ) : (
                  <Icon.KeyboardArrowRight color="#4e4e4e" />
                )}
              </div>
            )}
          </FilterRow>
        )}

        {this._renderChildren()}
      </Wrapper>
    );
  }

  _renderChildren = () => {
    let { node, onNodeClick, activeNode } = this.props;
    const { expand } = this.state;
    const hasChildren = node.children && node.children.length > 0;

    if (!expand || !hasChildren) {
      return null;
    }

    return node.children.map((c, idx) => (
      <TreeNodeWrapper
        key={c.name}
        activeNode={activeNode}
        onNodeClick={onNodeClick}
        css={node.name === "root" ? "" : "padding-left: 10px;"}
        node={c}
      />
    ));
  };

  _shouldExpand = () => {
    let { node, activeNode } = this.props;
    return activeNode.indexOf(node.name) === 0 && node.name !== activeNode;
  };
}

export default TreeNodeWrapper;
